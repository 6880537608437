import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function PageFaqs() {
  const [openIndex, setOpenIndex] = useState(null);

  const questions = [
    {
      question: "Q1. How to play game?",
      answer: (
        <div className="legal-content">
          <ul className="legal-ul">
            <li className="mt-0">Place a bet. The initial stake is determined before the start of a game. </li>
            <li>The maximum stake is 46637.8 INR and the minimum stake is 20 INR. </li>
            <li>As soon, the odds start to increase until a crash occurs.</li>
            <li>If you manage to press "Take winnings" before the crash, then your stake will be multiplied by the stated odds.</li>
            <li>The crash occurs at random, but the longer you stay in the game, the higher the odds and the more you win.</li>
            <li>If you have not taken your winnings by the time the crash happens, the game will end and your stake will be lost.</li>
            <li>All you need to succeed is a stable internet connection.</li>
          </ul>
        </div>
      ),
    },
    {
      question: "Q2. how to deposit?",
      answer: (
        <div className="legal-content">
          <p className="mt-0">You can deposit funds using either of the following methods:</p>

          <div className="main-sub-lable mt-3" style={{fontSize:"16px"}}>A. Scan QR Code:</div>
          <ul className="legal-ul">
            <li>Navigate to the "Wallet" section in your account.</li>
            <li>Select the "Scan QR Code" option.</li>
            <li>Scan the provided QR code with your mobile banking app.</li>
            <li>Enter the desired deposit amount and complete the payment.</li>
            <li>Attach a screenshot of the transaction as proof.</li>
          </ul>

          <div className="main-sub-lable mt-3" style={{fontSize:"16px"}}>B. UPI ID:</div>
          <ul className="legal-ul">
            <li>Navigate to the "Wallet" section in your account.</li>
            <li>Choose the "UPI ID" option.</li>
            <li>Enter the provided UPI ID in your UPI payment app.</li>
            <li>Complete the payment process, ensuring the accuracy of the UPI ID.</li>
            <li>Attach a screenshot of the transaction as proof.</li>
          </ul>
        </div>
      ),
    },
    {
      question: "Q3. How to withdraw?",
      answer: (
        <div className="legal-content">
          <p className="mt-0">To withdraw your winnings, follow these steps:</p>
          <ul className="legal-ul">
            <li>Navigate to the "Withdraw" section in your account.</li>
            <li>Enter the withdrawal amount and submit the request.</li>
            <li>The withdrawal amount will be displayed in your account.</li>
            <li>Our admin team will process the payment based on the bank details you provided in bank details section.</li>
          </ul>

        </div>
      ),
    },
  ];

  const handleAccordionClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <Helmet>
        <title>Frequently asked questions | Crash</title>
      </Helmet>

      <div className='breadcrumb-outer'>
          <div className='container-lg'>   
              <div className='breadcrumb-bx'>
                  <Link className='breadcrumb-link' to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                  <Link className='breadcrumb-link breadcrumb-active'>Faqs</Link>
              </div>
          </div>
      </div>

      <section className="mb-5">
        <div className="container-lg lagle-container">
          <div className="lagle-heading">FAQs</div>
          <div className="lagle-sub-heading">Frequently asked questions</div>

          <div className="faqs-accordion mt-4">
            {questions.map((item, index) => (
              <div className="acc-item" key={index}>
                <div
                  className={`acc-heding ${ openIndex === index ? "" : "active"}`}
                  onClick={() => handleAccordionClick(index)}
                >
                  {item.question}
                  <span className="down-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                    </svg>
                  </span>
                </div>

                <div
                  className={`acc-data ${openIndex === index ? "" : "active"}`}
                >
                  <div className="faqs-data">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
};
