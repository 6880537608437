import React from 'react';


export default function CommonModal() {
    return (
        <>

{/* --- Terms Modal Start --- */}
<div className="modal fade zoom" id="termsmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content custom-content">
            <div className="custom-modal-header">
                <div className="cmh-lable">Terms & conditions</div>

                <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                    </svg>
                </span>
            </div>
            <div className="custom-modal-data">
            <div className="legal-content px-3 pb-3">
                <p>1. Place a bet. The initial stake is determined before the start of a game.</p>
                <p>2. The maximum stake is 46637.8 INR and the minimum stake is 20 INR.</p>
                <p>3. As soon as each round begins, the odds start to increase until a crash occurs.</p>
                <p>4. If you manage to press "Take winnings" before the crash, then your stake will be multiplied by the stated odds.</p>
                <p>5. The crash occurs at random, but the longer you stay in the game, the higher the odds and the more you win.</p>
                <p>6. If you have not taken your winnings by the time the crash happens, the game will end and your stake will be lost.</p>
                <p>7. All you need to succeed is a stable internet connection (wired, Wi-Fi or LTE).</p>
            </div>
            </div>
        </div>
    </div>
</div>
{/* --- Terms Modal End --- */}

        </>
    );
}