import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import axios from 'axios';
import { API_HOST, live_token } from '../../configue/confifigue'

// Images Common File
// import * as Img from '../../components/Img';
import walletgrapics from '../../img/wallet.webp';
import QRcode from '../../img/paytmqr(1).jpg';
import capture from '../../img/icons/capture.png';
import FlashMessage from '../../components/FlashMessage'
import isEmpty from 'lodash/isEmpty';
import { useHeader } from '../../contexts/HeaderProvider';



// Design File
import Commonlayout from "./Commonlayout";
import EmptyBox from '../../components/EmptyBox';

export default function PageWallet() {
    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const { state } = useAuth();
    // const [balance, setBalance] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [hideWIthdrwa, sethideWIthdrwa] = useState(true);
    const [upiId, setUpiId] = useState('8980999290@paytm');
    const [upiIdCoupied,setupiIdCoupied] = useState(false);
    const { updateHeaderValue, headerValue } = useHeader();
    const [minumumAmountError, setMinumumAmountError] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setupiIdCoupied(false);  
        }, 2000);
    }, [upiIdCoupied]);

   
    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;

        if (isUserLoggedIn) {
            setUserLoggedIn(true);
            setUserEmail(loggedInUser.email);
        }
    }, [state.userData]);


    const copyToClipboard = () => {
        
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = upiId;

        document.body.appendChild(tempTextArea);

        tempTextArea.select();
        document.execCommand('copy');

        document.body.removeChild(tempTextArea);
        setupiIdCoupied(true);
       
        
    };





    useEffect(() => {

        const fetchData = async () => {
            try {
                const token = live_token
                const response = await axios.post(`${API_HOST}/get-wallet-records/`, {
                    email: userEmail,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.data.wallet.balance > 0) {
                    sethideWIthdrwa(false);
                }
                setTransactions(response.data.transactions);

            } catch (error) {
                console.error('API request error:', error);
            }
        };

        if (userEmail) {
            fetchData();
        }

    }, [userEmail]);



    const [addWlmoney, setAddWlmoney] = useState('mainscreen');
    const [amibSection, setAmibSection] = useState('QRCode');
    const [qrop, setqrop] = useState('qr1');
    const [upiop, setupiop] = useState('upiop1');

    const [transactionId, setTransactionid] = useState('');
    const [transactionIderror, setTransactionIderror] = useState(false);

    const [amount, setAmount] = useState(0);
    const [amounterror, setAmounterror] = useState(false);

    const [withdrAwamount, setWithdrAwamount] = useState(0);
    const [withdrAwamountError, setWithdrAwamountError] = useState(false);
    const [withdrawRequestPlaced, setwithdrawRequestPlaced] = useState(false);
    const [InsufficiantBalanceError, setInsufficiantBalanceError] = useState(false);
    const [minumumBalnceError, setMinumumBalnceError] = useState(false);


    const [ssuploaded, setSsuploaded] = useState(false)

   
    
    useEffect(() => {
        setTimeout(() => {
            setInsufficiantBalanceError(false);  
        }, 2000);
    }, [InsufficiantBalanceError]);

    useEffect(() => {
        setTimeout(() => {
            setMinumumBalnceError(false);  
        }, 2000);
    }, [minumumBalnceError]);


    useEffect(() => {
        setTimeout(() => {
            setwithdrawRequestPlaced(false);  
        }, 2000);
    }, [withdrawRequestPlaced]);

    useEffect(() => {
        setTimeout(() => {
            setMinumumAmountError(false);  
        }, 2000);
    }, [minumumAmountError]);




    const handleaddmoneycancel = () => {
        setTransactionid('');
        setAmount(0);
        setSelectedFiles([]);
        setSsuploaded(false);
        setAddWlmoney("mainscreen");
    };

    const handleaddWlmoney = (addWlmoneyId) => {
        setAddWlmoney(addWlmoneyId);
    };

    const handleAmibSection = (AmibSectionId) => {
        setAmibSection(AmibSectionId);
    };

    const handleqrop = (qropId) => {
        setqrop(qropId);
    };

    const handleupiop = (upiopId) => {
        setupiop(upiopId);
    };

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFileserror, setSelectedFileserror] = useState(false);







    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        setSelectedFileserror(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const preventDefault = (e) => {
        e.preventDefault();
    };

    const deleteFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    const handleAmountChange = (e) => {
        setAmounterror(false);
        const enteredValue = e.target.value;
        const numericValue = enteredValue.replace(/\D/g, '');
        setAmount(numericValue);

    };


    const handleWithdrawalAmountChange = (e) => {
        setWithdrAwamountError(false);
        const enteredValue = e.target.value;
        const numericValue = enteredValue.replace(/[^0-9]/g, '');
        setWithdrAwamount(numericValue);
    };

    // ...


    const showFileUploadInput = selectedFiles.length === 0;

    const uploadFiles = async () => {
        if (selectedFiles.length === 1 && transactionId.trim() && amount && amount >= 300 && userLoggedIn) {
            try {
                const token = live_token
                const formData = new FormData();

                formData.append('amount', amount);
                formData.append('transactionId', transactionId.trim());
                formData.append('file', selectedFiles[0]);
                formData.append('email', userEmail)

                const response = await fetch(`${API_HOST}/upload-payments-screenshots/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    body: formData,
                });

                if (response.ok) {
                    const responseData = await response.json();
                    if (responseData.error === '0' && responseData.msg === 'uploaded successfully') {
                        setSsuploaded(true);
                        setTransactionid('');
                        setAmount(0);
                        setSelectedFiles([]);
                        setTimeout(() => {
                            setSsuploaded(false);
                            setAddWlmoney("mainscreen");
                        }, 2000);
                    }
                }
            } catch (error) {
                // Handle error
            }
        } else {
            if (selectedFiles.length !== 1) {
                setSelectedFileserror(true);
            }
            if (!transactionId.trim()) {
                setTransactionIderror(true);
            }
            if (!amount) {
                setAmounterror(true);
            }
            if (!userLoggedIn) {
                alert('not logged in ')
            }
            if (amount < 300) {
                setMinumumAmountError(true)
            }
        }
    };




    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };






    const WIthdrawalAPi = async () => {
        if (withdrAwamount && withdrAwamount <= headerValue && withdrAwamount >= 1000 ) {
            try {
                const token = live_token;

                const response = await axios.post(`${API_HOST}/withdrawal/`, {
                    amount: withdrAwamount,
                    email: userEmail,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    const responseData = response.data;

                    if (responseData.error === '0' && responseData.msg === 'uploaded successfully') {
                        updateHeaderValue(response.data.wallet.balance);
                        setwithdrawRequestPlaced(true);
                        setWithdrAwamount(0);

                        setTimeout(() => {
                            setwithdrawRequestPlaced(false);
                            setAddWlmoney("mainscreen");
                        }, 2000);
                    } else {
                        console.error("Unexpected response data:", responseData);
                    }
                } else {
                    console.error("Server returned an error status code:", response.status);
                }
            } catch (error) {
                console.error("Error making the API request:", error);
            }
        } else {
            if (!withdrAwamount) {
                setWithdrAwamountError(true);
            }
            if (withdrAwamount > headerValue) {
                setInsufficiantBalanceError(true);
            }
            if (withdrAwamount < 1000){
                setMinumumBalnceError(true);
            }
        }
    };


    return (
        <Commonlayout>

            <Helmet>
                <title>Wallet | Crash</title>
            </Helmet>

            {/* --- Profile Bx Start --- */}
            <div className='amib-inner-item'>

                {/* --- Mainscreen Section --- */}
                <div className={`amib-900-width ${addWlmoney === 'mainscreen' ? '' : 'd-none'} `} id="mainscreen">

                    <div className={`amib-i-header ${addWlmoney === 'mainscreen' ? '' : 'd-none'} `}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                        </svg>
                        Wallet
                    </div>

                    <div className="amib-item amib-item-bg-none p-0 rounded-0">
                        <form action="">
                            <div className="wallet-amount-bx">

                                {headerValue === 0 ? (
                                    <div className="wab-data-bx">
                                        <div className="wdb-money">{'\u20B9'}{headerValue}</div>
                                        <div className="wdb-lable">Current wallet balance</div>
                                    </div>
                                ) : (
                                    <div className="wab-data-bx">
                                        <div className="wdb-note">A minimum of <span>₹300</span> is required to add money to the wallet & amount will be transferred to your wallet account after admin approval.</div>
                                        <Link className="wdb-link" to="/how-to-make-deposit">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                                            </svg>
                                            How to make deposit
                                        </Link>
                                    </div>
                                )}

                                <div className="wallet-amount-btns">
                                    <button className="add-money-btn" type="button" onClick={() => handleaddWlmoney('addmoney')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                        Add money
                                    </button>
                                    {!hideWIthdrwa && <button className="add-money-btn withdrawal-btn" type="button" onClick={() => handleaddWlmoney('withdrawalmoney')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                        </svg>
                                        Withdrawal
                                    </button>}
                                </div>

                                <img className="walletgrapics" src={walletgrapics} alt="walletgrapics" />
                            </div>
                        </form>


                        <div className="amib-i-header mt-4 pt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                            All transitions history
                        </div>

                        {isEmpty(transactions) && <EmptyBox esh="There are no transitions history" />}


                        {transactions.length > 0 && 
                        <div className="custom-table-responsive">
                            <table className="custom-table">

                                <thead>
                                    <tr>
                                        <th>Transitions</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    {transactions.map((transaction, index) => (
                                        <React.Fragment key={index}>
                                            {transaction.transaction_status === 'CREDITED' || transaction.transaction_status === 'DISCOUNT' ? (
                                                <tr>
                                                    <td>
                                                        <div className="ct-data-outer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                                            </svg>
                                                            <div className="ct-data-bx">
                                                                <div className="ct-lable">Added to wallet {`\u20B9${transaction.amount}`}</div>
                                                                <div className="ct-date">{formatDate(transaction.created_at)}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ct-data-bx ct-added">
                                                            <div className="ct-amount-lable"> {transaction.approved ? 'Credited' : 'Processing...'}</div>
                                                            <div className="ct-amount">{transaction.transaction_status === 'DISCOUNT' ? '+bonus' : ''}</div> 
                                                            <div className="ct-amount">+ {`\u20B9${transaction.amount}`}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td>
                                                        <div className="ct-data-outer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                                            </svg>
                                                            <div className="ct-data-bx">
                                                                <div className="ct-lable">Wallet to Bank {`\u20B9${transaction.amount}`}</div>
                                                                <div className="ct-date">{formatDate(transaction.created_at)}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ct-data-bx ct-less">
                                                            <div className="ct-amount-lable">{transaction.approved ? 'Debited' : 'Processing...'}</div>
                                                            <div className="ct-amount">- {`\u20B9${transaction.amount}`}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}


                                </tbody>

                            </table>
                        </div>
                        }
                        <div className="amib-save-btn-bx mt-4">
                            {/* <button type="button" className="common-submit-btn">Load More</button> */}
                            {/* <div className="lsb-loader-btn">
                                <img src={Img.loading} alt="Please wait" />Please wait ....
                            </div> */}
                        </div>

                    </div>

                </div>

                {/* --- Addmoney Section --- */}
                <div className={`amib-900-width ${addWlmoney === 'addmoney' ? '' : 'd-none'} `} id="addmoney">

                    <div className="amib-i-header">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        Add money to wallet

                        <div className='amib-i-cancel' onClick={handleaddmoneycancel}>Cancel</div>
                    </div>

                    <div className="amib-item pb-3">

                        <div className="amib-inner-tabbx commonscrollbarhide mt-4 mt-md-0">
                            <div className={`ait-link ${amibSection === 'QRCode' ? 'active' : ''}`} onClick={() => handleAmibSection('QRCode')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                                </svg>
                                Scan Qr Code
                            </div>
                            <div className={`ait-link ${amibSection === 'ownerupi' ? 'active' : ''}`} onClick={() => handleAmibSection('ownerupi')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                </svg>
                                UPI Id
                            </div>
                            {/* 
                            <div className={`ait-link ${amibSection === 'bankdetail' ? 'active' : ''}`} onClick={() => handleAmibSection('bankdetail')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                </svg>
                                Bank Detail
                            </div> 
                            */}
                        </div>

                        <div className="signinup-group-nots">
                            <span>Please note:</span>
                            <p>A minimum of <span>₹300</span> is required to add money to the wallet & amount will be transferred to your wallet account after admin approval.</p>
                            <Link to="/how-to-make-deposit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                                </svg>
                                How to make deposit
                            </Link>
                        </div>

                        <div className='amib-loadmoney-bx'>

                            <div className={`amib-i-tab ${amibSection === 'QRCode' ? 'active' : ''}`} id="QRCode">
                                <div className="amib-i-sublable">Scan the QR code and submit the transaction ID you got after making the payment</div>

                                <div className="loadmoney-tab-list">
                                    <div className={`ltl-item ${qrop === 'qr1' ? 'active' : ''}`} onClick={() => handleqrop('qr1')}>QR 1</div>
                                    <div className={`ltl-item ${qrop === 'qr2' ? 'active' : ''}`} onClick={() => handleqrop('qr2')}>QR 2</div>
                                    <div className={`ltl-item ${qrop === 'qr3' ? 'active' : ''}`} onClick={() => handleqrop('qr3')}>QR 3</div>
                                    <div className={`ltl-item ${qrop === 'qr4' ? 'active' : ''}`} onClick={() => handleqrop('qr4')}>QR 4</div>
                                    <div className={`ltl-item ${qrop === 'qr5' ? 'active' : ''}`} onClick={() => handleqrop('qr5')}>QR 5</div>
                                </div>

                                <div className='loadmoney-data-bx p-0'>
                                    <img className={` ${qrop === 'qr1' ? '' : 'd-none'}`} id="qr1" src={QRcode} alt="QRcode" />
                                    <img className={` ${qrop === 'qr2' ? '' : 'd-none'}`} id="qr2" src={capture} alt="QRcode" />
                                    <img className={` ${qrop === 'qr3' ? '' : 'd-none'}`} id="qr3" src={QRcode} alt="QRcode" />
                                    <img className={` ${qrop === 'qr4' ? '' : 'd-none'}`} id="qr4" src={capture} alt="QRcode" />
                                    <img className={` ${qrop === 'qr5' ? '' : 'd-none'}`} id="qr5" src={QRcode} alt="QRcode" />
                                </div>

                            </div>

                            <div className={`amib-i-tab ${amibSection === 'ownerupi' ? 'active' : ''}`} id="ownerupi">
                                <div className="amib-i-sublable">Submit the transaction ID you got after making the payment</div>
                                
                                <div className="loadmoney-tab-list">
                                    <div className={`ltl-item ${upiop === 'upiop1' ? 'active' : ''}`} onClick={() => handleupiop('upiop1')}>ID 1</div>
                                    <div className={`ltl-item ${upiop === 'upiop2' ? 'active' : ''}`} onClick={() => handleupiop('upiop2')}>ID 2</div>
                                    <div className={`ltl-item ${upiop === 'upiop3' ? 'active' : ''}`} onClick={() => handleupiop('upiop3')}>ID 3</div>
                                    <div className={`ltl-item ${upiop === 'upiop4' ? 'active' : ''}`} onClick={() => handleupiop('upiop4')}>ID 4</div>
                                    <div className={`ltl-item ${upiop === 'upiop5' ? 'active' : ''}`} onClick={() => handleupiop('upiop5')}>ID 5</div>
                                </div>

                                <div className="loadmoney-data-bx py-2">

                                    <div className="ldb-data-copy" onClick={copyToClipboard} title="Copy UPI ID">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                        </svg>
                                    </div>

                                    <div className="row mx-0">
                                        <div className="col-md-12 col-sm-6 ldb-data-item">
                                            UPI ID
                                            <span>{upiId}</span>
                                        </div>
                                        <div className="col-md-12 col-sm-6 ldb-data-item">UPI ID
                                            <span className={` ${upiop === 'upiop1' ? '' : 'd-none'}`} id="upiop1">8980999290@paytm</span>
                                            <span className={` ${upiop === 'upiop2' ? '' : 'd-none'}`} id="upiop2">123456789@paytm</span>
                                            <span className={` ${upiop === 'upiop3' ? '' : 'd-none'}`} id="upiop3">987654321@paytm</span>
                                            <span className={` ${upiop === 'upiop4' ? '' : 'd-none'}`} id="upiop4">456799123@paytm</span>
                                            <span className={` ${upiop === 'upiop5' ? '' : 'd-none'}`} id="upiop5">789456123@paytm</span>
                                        </div>
                                    </div>

                                </div>

                                {/* 
                                <div className="loadmoney-data-bx py-2">

                                    <div className="ldb-data-copy" title="Copy UPI ID">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                        </svg>
                                    </div>

                                    <div className="row mx-0">
                                        <div className="col-md-12 col-sm-6 ldb-data-item">UPI ID
                                            <span>8980999290@paytm</span>
                                        </div>
                                    </div>

                                </div> 
                                */}

                            </div>

                            {/* 
                            <div className={`amib-i-tab ${amibSection === 'bankdetail' ? 'active' : ''}`} id="bankdetail">
                                <div className="amib-i-sublable">Scan the QR code and submit the transaction ID you got after making the payment</div>
                                <div className="loadmoney-data-bx">

                                    <div className="row mx-0">
                                        <div className="col-md-12 col-sm-6 ldb-data-item">Account Name:
                                            <span>Crash</span>
                                        </div>

                                        <div className="col-md-12 col-sm-6 ldb-data-item">Bank Name:
                                            <span>Kotak Mahindra Bank</span>
                                        </div>

                                        <div className="col-md-12 col-sm-6 ldb-data-item">Branch:
                                            <span>Satellite Branch</span>
                                        </div>

                                        <div className="col-md-12 col-sm-6 ldb-data-item">Account Number:
                                            <span>0801199708011997</span>
                                        </div>

                                        <div className="col-md-12 col-sm-6 ldb-data-item">IFSC Code:
                                            <span>KKBK08011997</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            */}

                            <div className="amib-i-tab active mt-md-0 mt-4">
                                <form action="" className="addmoneyform ps-md-4">

                                    <div className="row mx-md-0">

                                        <div className="col-sm-6">
                                            <div className="signinup-group sgli">
                                                <div className="group__label">Amount</div>
                                                <input type="number" value={amount} onChange={(e) => handleAmountChange(e)} placeholder="Enter amount" />
                                                <span className="group_left_icon" style={{ fontSize: "18px", width: "40px" }}>₹</span>
                                                {amounterror && <span className="form-error-msg">You must provide amount</span>}
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="signinup-group">
                                                <div className="group__label">Transaction ID</div>
                                                <input value={transactionId} type="text" placeholder="Enter transaction id" onChange={(e) => { setTransactionid(e.target.value); setTransactionIderror(false); }} />
                                                {transactionIderror && <span className="form-error-msg">You must provide transactionid</span>}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="signinup-group">
                                                <label className="group__label">Attach payment screenshot</label>
                                                {showFileUploadInput && (
                                                    <>
                                                        <label htmlFor="fileupload" className="fileupload" onDrop={handleDrop} onDragOver={preventDefault}>
                                                            <img className="feud-img" src={capture} alt="Payment screenshot" />
                                                            <div className="feud-lable">Drag a photo or <span>Browse</span></div>
                                                        </label>
                                                        <input hidden type="file" name="fileupload" id="fileupload" accept="image/*" onChange={handleFileSelect} />
                                                    </>
                                                )}

                                                <ol className="fileupload-list">
                                                    {selectedFiles.map((file, index) => (
                                                        <li className="fileupload-item filstn  mt-0 mb-4" key={index}>
                                                            {file.type.startsWith('image/') && (
                                                                <>
                                                                    <img src={URL.createObjectURL(file)} alt={file.name} />
                                                                    <span className="clear-input-icon" onClick={() => deleteFile(index)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"></path>
                                                                        </svg>
                                                                    </span>
                                                                </>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ol>

                                            </div>
                                        </div>
                                        {selectedFileserror && <span className="form-error-msg">You must provide payment screenshot</span>}
                                        {minumumAmountError && <FlashMessage type="error" isvisible={true} message="minimum ₹300 allowed to diposite" />}

                                    </div>

                                    <div className="amib-save-btn-bx mt-1">
                                        <button type="button" onClick={uploadFiles} className="common-submit-btn">Submit</button>
                                        {/* 
                                        <div className="lsb-loader-btn">
                                            <img src={Img.loading} alt="Please wait" />Please wait ....
                                        </div> 
                                        */}
                                    </div>
                                    {ssuploaded && <FlashMessage type="success" isvisible={true} message="Payment Informations uploaded successfully" />}
                                    {upiIdCoupied && <FlashMessage type="success" isvisible={true} message="Copied" />}
                                </form>
                            </div>
                        </div>

                    </div>

                </div>

                {/* --- Withdrawalmoney Section --- */}
                <div className={`amib-576-width ${addWlmoney === 'withdrawalmoney' ? '' : 'd-none'} `} id="withdrawalmoney">

                    <div className="amib-i-header">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                        </svg>
                        Withdrawal money
                        <div className='amib-i-cancel' onClick={handleaddmoneycancel}>Cancel</div>
                    </div>

                    <div className="amib-item pb-2">
                        <form action="">

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="signinup-group">
                                        <div className="group__label">Amount</div>
                                        <input type="text" value={withdrAwamount} onChange={(e) => handleWithdrawalAmountChange(e)} placeholder="Enter amount" />
                                        {withdrAwamountError && <span className="form-error-msg">You must provide amount</span>}
                                        {withdrawRequestPlaced && <FlashMessage type="success" isvisible={true} message="Your Withdrawal request has been placed successfully" />}
                                        {InsufficiantBalanceError && <FlashMessage type="error" isvisible={true} message="Insufficiant balance in your wallet" />}
                                        {minumumBalnceError && <FlashMessage type="error" isvisible={true} message="minimum ₹1000 allowed to withdraw" />}
                                    </div>
                                </div>
                            </div>

                            <div className="signinup-group-nots">
                                <span>Please note:</span>
                                <p>Withdrawal amount will be transferred to your bank account after admin approval.</p>
                            </div>

                            <div className="amib-save-btn-bx mt-1">
                                <button type="button" onClick={WIthdrawalAPi} className="common-submit-btn">Submit</button>
                                {/* <div className="lsb-loader-btn">
                                    <img src={Img.loading} alt="Please wait" />Please wait ....
                                </div> */}
                            </div>
                            
                        </form>
                    </div>  

                </div>

            </div>
            {/* --- Profile Bx End --- */}

        </Commonlayout>


    );
};
