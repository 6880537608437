import React, { useState,useEffect }  from 'react';
import { Helmet } from "react-helmet-async";
import { useAuth,getLoggedInUserData  } from '../../contexts/AuthContext';
import axios from 'axios';
import {API_HOST,live_token} from '../../configue/confifigue'
import FlashMessage from '../../components/FlashMessage'


// Images Common File
import * as Img from '../../components/Img';

// Design File
import Commonlayout from "./Commonlayout";


export default function PageBankDetail() {


    const [userLoggedIn,setUserLoggedIn] = useState(false)
    const [user,setUser] = useState('')
    const { state } = useAuth();

    const { dispatch } = useAuth();

      useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
        if (isUserLoggedIn) {
          setUserLoggedIn(true);
          setUser(loggedInUser);
        
          setaccount_Holder_Name(loggedInUser.account_holder_name || '');
          setBankName(loggedInUser.bank_name || '');
          setBranch_Name(loggedInUser.branch_name || '');
          setAccount_Number(loggedInUser.account_number || '');
          setIfsc_code(loggedInUser.ifsc_code || '');
          setUpiId(loggedInUser.upi_id || '');
        }
      }, [state.userData]);

    // Screen section Tabbing Js
    const [amibSection, setAmibSection] = useState('accountdetail');

    

    const [account_Holder_Name, setaccount_Holder_Name] = useState('');
    const [account_Holder_NameError, setaccount_Holder_NameError] = useState(false);
    const [invalidAHNameError, setinvalidAHNameError] = useState(false);

    const [BankName, setBankName] = useState('');
    const [BankNameError, setBankNameError] = useState(false);
    const [invalidBankNameError, setinvalidBankNameError] = useState(false);

    const [Branch_Name, setBranch_Name] = useState('');
    const [Branch_NameError, setBranch_NameError] = useState(false);
    const [invalidBranchNameError, setinvalidBranchNameError] = useState(false);


    const [Account_Number, setAccount_Number] = useState('');
    const [Account_NumberError, setAccount_NumberError] = useState(false);
   
    const [Ifsc_code, setIfsc_code] = useState('');
    const [Ifsc_codeError, setIfsc_codeError] = useState(false);


    const [upiID,setUpiId]=useState('');
    const [upiIDError,setUpiIDError]=useState('');
    const [upiIdsetSuccsess,SetUpiIdsetSuccsess] = useState(false);
    
    const[detialsUpdated,setdetialsUpdated]=useState(false);



    const handleAmibSection = (AmibSectionId) => {
        setAmibSection(AmibSectionId);
    };

      // VALIDATIONS


    const handleAcoountHolderNameChange = (e) => {
        setaccount_Holder_NameError(false);
        setinvalidAHNameError(false);
        const enteredValue = e.target.value;
        const isValid = /^[a-zA-Z]*$/.test(enteredValue); 
      
        if (isValid) {
            setaccount_Holder_Name(enteredValue);  
        } else {
            setinvalidAHNameError(true);
        }
      };

      const handleBankNameChange = (e) => {
        setBankNameError(false);
        setinvalidBankNameError(false);
        const enteredValue = e.target.value;
        const isValid = /^[a-zA-Z]*$/.test(enteredValue); 
      
        if (isValid) {
            setBankName(enteredValue);  
        } else {
            setinvalidBankNameError(true);
        }
      };    
     

      const handleBranchNameChange = (e) => {
        setBranch_NameError(false);
        setinvalidBranchNameError(false);
        const enteredValue = e.target.value;
        const isValid = /^[a-zA-Z]*$/.test(enteredValue); 
      
        if (isValid) {
            setBranch_Name(enteredValue);  
        } else {
            setinvalidBranchNameError(true);
        }
      };   



      const handleBankAccountNumberChange = (e) => {
        const enteredValue = e.target.value;
        const numericValue = enteredValue.replace(/\D/g, ''); 
        setAccount_Number(numericValue);
      
      };
        // END VALIDATIONS

    const handleContinueClick = async () => {

        if (account_Holder_Name.trim() && BankName.trim() && Branch_Name.trim() && Account_Number.trim() && Ifsc_code.trim() ){
          try {
              const token = live_token
  
              const response = await axios.put(`${API_HOST}/user/${user.id}/`, {
                account_holder_name: account_Holder_Name,
                bank_name: BankName,
                branch_name: Branch_Name,
                account_number: Account_Number,
                ifsc_code: Ifsc_code,
              }, {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  },
              });
              
            if(response.data.error === '0' && response.data.msg === 'account updated') {
                localStorage.clear();
                localStorage.setItem('userData', JSON.stringify(response.data.userData));
                dispatch({ type: 'LOGIN', payload: response.data.userData });
                setdetialsUpdated(true)
             }  
              
          } catch (error) {
              console.error('API request error:', error);
          }
        }else{ 

            if (!account_Holder_Name.trim()) {
                setaccount_Holder_NameError(true);
              }
            if (!BankName.trim()){
                setBankNameError(true);
            }   
            if (!Branch_Name.trim()){
                setBranch_NameError(true);
            }
            if(!Account_Number.trim()){
                setAccount_NumberError(true);
            }
            if(!Ifsc_code.trim()){
                setIfsc_codeError(true);
            }
            
        }
      };


      const handleUpiIdChange = (e) => {
        setUpiIDError(false);
        setUpiId(e.target.value);
      };

      const handleUpiId = async () => {

        if (upiID.trim()){
          try {
              const token = live_token
  
              const response = await axios.put(`${API_HOST}/user/${user.id}/`, {
                upi_id: upiID,
              }, {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  },
              });
              
            if(response.data.error === '0' && response.data.msg === 'account updated') {
                setUpiId('');
                localStorage.clear();
                localStorage.setItem('userData', JSON.stringify(response.data.userData));
                dispatch({ type: 'LOGIN', payload: response.data.userData });
                SetUpiIdsetSuccsess(true);
             }  
              
          } catch (error) {
              console.error('API request error:', error);
          }
        }else{ 

            if (!upiID.trim()) {
                setUpiIDError(true);
              }
           
            
        }
      };
  

  return (
    <Commonlayout>
        
    <Helmet>
        <title>Bank Details | Crash</title>
    </Helmet>

    {/* --- Profile Bx Start --- */}
    <div className="amib-inner-item">
        <div className={` ${amibSection === 'UPIId' ? 'amib-576-width' : 'amib-768-width'} `}>
            <div className="amib-i-header">
                <svg style={{position:"relative",top:"-3px"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                </svg>
                Bank Details
            </div>

            <div className="amib-item pb-2">

                <div className="amib-inner-tabbx commonscrollbarhide mt-3 mt-md-0 mb-4">
                    <div className={`ait-link ${amibSection === 'accountdetail' ? 'active' : ''}`} onClick={() => handleAmibSection('accountdetail')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                        </svg>
                        Bank Account
                    </div>
                    <div className={`ait-link ${amibSection === 'UPIId' ? 'active' : ''}`} onClick={() => handleAmibSection('UPIId')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                        </svg>
                        UPI Id
                    </div>
                </div>

                <div className={`amib-i-tab ${amibSection === 'accountdetail' ? 'active' : ''}`} id="accountdetail">
                    <form action="">
                        <div className="row">

                            <div className="col-sm-6 pe-sm-2">
                                <div className="signinup-group">
                                    <div className="group__label">Account Holder Name</div>
                                    <input value={account_Holder_Name} onChange={(e) => handleAcoountHolderNameChange(e)} type="text" placeholder="Enter account holder name"/>
                                    {account_Holder_NameError && <span className="form-error-msg">You must provide an account holder name</span>}
                                    {invalidAHNameError && <span className="form-error-msg">Account holder name can not be number</span>}
                                </div>
                            </div>
           
                            <div className="col-sm-6 ps-sm-2">
                                <div className="signinup-group">
                                    <div className="group__label">Bank Name</div>
                                    <input type="text" value={BankName} onChange={(e) => handleBankNameChange(e)} placeholder="Enter bank name"/>
                                    {BankNameError && <span className="form-error-msg">You must provide a bank name</span>}
                                    {invalidBankNameError && <span className="form-error-msg">Bank name can not be number</span>}
                                </div>
                            </div>

                            <div className="col-sm-6 pe-sm-2">
                                <div className="signinup-group">
                                    <div className="group__label">Branch Name</div>
                                    <input value={Branch_Name} onChange={(e) => handleBranchNameChange(e)} type="text" placeholder="Enter branch name"/>
                                    {Branch_NameError && <span className="form-error-msg">You must provide a branch name</span>}
                                    {invalidBranchNameError && <span className="form-error-msg">Branch name can not be number</span>}
                                </div>
                            </div>
                       
                            <div className="col-sm-6 ps-sm-2">
                                <div className="signinup-group">
                                    <div className="group__label">Account Number</div>
                                    <input value={Account_Number} onChange={(e) => handleBankAccountNumberChange(e)}  type="text" placeholder="Enter account number"/>
                                    {Account_NumberError && <span className="form-error-msg">You must provide an account number</span>}
                                </div>
                            </div>
    
                            <div className="col-sm-6 pe-sm-2">
                                <div className="signinup-group">
                                    <div className="group__label">IFSC Code</div>
                                    <input value={Ifsc_code} onChange={(e) => {setIfsc_code(e.target.value); setIfsc_codeError(false);}} type="text" placeholder="Enter IFSC Code"/>
                                    {Ifsc_codeError && <span className="form-error-msg">You must provide IFSC code</span>}
                                </div>
                            </div>

                        </div>

                        <div className="amib-save-btn-bx mt-1">
                            <button type="button" onClick={handleContinueClick} className="common-submit-btn">Update account</button>
                            {/* <div className="lsb-loader-btn">
                                <img src={Img.loading} alt="Please wait" />Please wait ....
                            </div> */}
                        </div>
                        {detialsUpdated && <FlashMessage type="success" isvisible={true} message="Bank Account Details Updated" />}
                    </form>
                </div>

                <div className={`amib-i-tab ${amibSection === 'UPIId' ? 'active' : ''}`} id="UPIId">
                    <form action="">
                        <div className="row">

                            <div className="col-sm-12">
                                <div className="signinup-group">
                                    <div className="group__label">UPI Id</div>
                                    <input type="text" value={upiID} onChange={handleUpiIdChange} placeholder="Enter upi id"/>
                                    {upiIDError && <span className="form-error-msg">You must provide an UPI ID</span>}
                                </div>
                            </div>

                        </div>

                         <div className="amib-save-btn-bx mt-1">
                            <button type="button" onClick={handleUpiId} className="common-submit-btn">Update UPI</button>
                            {/* <div className="lsb-loader-btn">
                                <img src={Img.loading} alt="Please wait" />Please wait ....
                            </div> */}
                        </div> 
                        {upiIdsetSuccsess && <FlashMessage type="success" isvisible={true} message="UPI ID Updated" />}
                    </form>
                </div>

  
            </div>

        </div>
    </div>
    {/* --- Profile Bx End --- */}

    </Commonlayout>

    
  );
};
