import React, { useEffect, useState } from 'react';

// Css File
import '../css/flashmessage.css'

export default function FlashMessagetwo(props) {
    const [isPopupVisible, setPopupVisible] = useState(((props?.color == 'green' && props.isvisible == true) ? true : false));
    const [isPopupVisibleError, setPopupVisibleError] = useState(((props?.color == 'red' && props.isvisible == true) ? true : false));

    
    useEffect(() => {
        let popupTimer;

        if (isPopupVisible) {
            // Start a timer to hide the popup after 5 seconds
            popupTimer = setTimeout(() => {
                setPopupVisible(false);
            }, 2000);
        }

        return () => {
            // Clear the timer when the component unmounts or when isPopupVisible changes
            clearTimeout(popupTimer);
        };
    }, [isPopupVisible]);

    const handlefmp = () => {
        setPopupVisible(true);
    };

    const handlefmpclose = () => {
        setPopupVisible(false);
    };




    useEffect(() => {
        let popupErrorTimer;

        if (isPopupVisibleError) {
            // Start a timer to hide the popup after 5 seconds
            popupErrorTimer = setTimeout(() => {
                setPopupVisibleError(false);
            }, 2000);
        }

        return () => {
            // Clear the timer when the component unmounts or when isPopupVisible changes
            clearTimeout(popupErrorTimer);
        };
    }, [isPopupVisible]);

    const handlefmpError = () => {
        setPopupVisibleError(true);
    };

    const handlefmpcloseError = () => {
        setPopupVisibleError(false);
    };


    return (
        <>
            {/* <div className="cmp-btn" onClick={handlefmp}><i className="fa fa-commenting-o" aria-hidden="true"></i></div> */}

            {/* --- for color fmt-red, fmt-green --- */}
            <div className={`flash-message-two ${isPopupVisible && props?.color === 'green' ? 'active fmt-green' : ''}`}>
                <span className="close-icon msg-close" onClick={handlefmpclose}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                </span>
                winning amount <span> INR {props?.amount ? props.amount : ''}</span> credited in your wallet.
            </div>

            <div className={`flash-message-two ${isPopupVisibleError && props?.color === 'red' ? 'active fmt-red' : ''}`}>
                <span className="close-icon msg-close" onClick={handlefmpcloseError}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                </span>
                lossing amount <span> INR {props?.amount ? props.amount : ''}</span> debited from your wallet.
            </div>

        </>
    );
}
