import React, { useState,useEffect } from 'react';
import {Link,useNavigate } from "react-router-dom";
import axios from 'axios';
// Images Common File
import * as Img from '../../components/Img';
import {API_HOST,live_token} from '../../configue/confifigue'
import FlashMessage from '../../components/FlashMessage'
import { useAuth,getLoggedInUserData  } from '../../contexts/AuthContext';

// Css File
import '../../css/signinup.css'


const hidepassSvg = () => {
    return(
    <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>
    )
}

const viewpassSvg = () => {
    return(
    <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
    )
}

export default function PageSignUp() {
    const [userLoggedIn,setUserLoggedIn] = useState(false);
    const [isLoggedIn,setIsLoggedIn] = useState(false);
    const { dispatch,state } = useAuth();
    const navigate = useNavigate();
 
    const [Firstname, setFirstname] = useState('');
    const [FirstnameError, setFirstnameError] = useState(false);
    const [invalidFirstnameError, setInvalidFirstnameError] = useState(false);

    const [Lastname, setLastname] = useState('');
    const [LastnameError, setLastnameError] = useState(false);
    const [invalidLastnameError, setInvalidLastnameError] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [samePhoneNumbererror,setsamePhoneNumbererror]=useState(false);
    const [invalidPhoneNumberError, setInvalidPhoneNumberError] = useState(false);

    const [email, setemail] = useState('');
    const [emailError, setemailError] = useState(false);
    const [sameEmailerror,setSameEmailerror]=useState(false);
    const [invalidemailError, setinvalidemailError] = useState(false);
    

    const [gender, setGender] = useState('Male');

    const [ReferCode, setReferCode] = useState('');

    const[accountRegister,setAccountRegister]=useState(false);
    

    


    const [isbodShow, setisbodShow] = useState(true);
    const [bodValue, setbodValue] = useState('');
    const [dobError, setdobError] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [diffrentPasswordError,setDiffrentPasswordError] = useState(false);
    const [passwordRequirementsMet, setPasswordRequirementsMet] = useState(false);
    

    

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
      
        if (isUserLoggedIn) {
          setUserLoggedIn(true);
          navigate('/')
        }
    }, [state.userData]);



    // VALIDATIONS


    const handleFirstNameChange = (e) => {
        setFirstnameError(false);
        setInvalidFirstnameError(false);
        const enteredValue = e.target.value;
        const isValid = /^[a-zA-Z]*$/.test(enteredValue); 
      
        if (isValid) {
          setFirstname(enteredValue);  
        } else {
            setInvalidFirstnameError(true);
        }
      };

      const handleLastNameChange = (e) => {
        setLastnameError(false);
        setInvalidLastnameError(false);
        const enteredValue = e.target.value;
        const isValid = /^[a-zA-Z]*$/.test(enteredValue); 
      
        if (isValid) {
            setLastname(enteredValue);  
        } else {
            setInvalidLastnameError(true);
        }
      };
      

      const handlePhoneNumberChange = (e) => {
        setInvalidPhoneNumberError(false);
        setPhoneNumberError(false);
        const enteredValue = e.target.value;
        const numericValue = enteredValue.replace(/\D/g, ''); 
      
        if (numericValue.length > 10) {
          setInvalidPhoneNumberError(true);
        } else {
            setPhoneNumber(numericValue);
        }
      };



      const handleEmailChange = (e) => {
        setemailError(false)
        setinvalidemailError(false)
        const enteredEmail = e.target.value;
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(enteredEmail);
    
        setemail(enteredEmail);
     
        if (isValidEmail) {
            setinvalidemailError(false);
        } else {
            setinvalidemailError(true);
        }
      };



      const handleReferCodeChange = (e) => {
        const enteredValue = e.target.value; 
        setReferCode(enteredValue);
      };
    

    // END VALIDATIONS 

    const handlebodChange = (e) => {
      const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      const formattedValue = formatbod(value);
      setisbodShow(value === '');
      setbodValue(formattedValue);
      setdobError(false);
    };
  
    const formatbod = (value) => {
      if (value.length === 0) return '';
      if (value.length <= 2) return value;
      if (value.length <= 4) return `${value.slice(0, 2)}/${value.slice(2)}`;
      return `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
    };

    const handlebodClear = () => {
        setbodValue("");
        setisbodShow(true)
    };


    const handleContinueClick = async () => {

        if (Firstname.trim() && Lastname.trim() && phoneNumber.trim() && email.trim() && gender.trim() && bodValue.trim() && password.trim() && confirmPassword.trim() && password === confirmPassword){

          try {
              const token = live_token
  
              const response = await axios.post(`${API_HOST}/user/`, {
                first_name: Firstname,
                last_name: Lastname,
                phone_number: phoneNumber,
                email: email,
                gender: gender,
                date_of_birth: bodValue,
                password : password,
                confirm_password : confirmPassword,
                refer_code:ReferCode,
              }, {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  },
              });
              
             if(response.data.error === '1' && response.data.msg === 'Phone number already exist!'){
                setsamePhoneNumbererror(true);
                setPhoneNumberError(true);
                
             }else if (response.data.error === '1' && response.data.msg === 'Email already exists!'){
                setemailError(true);
                setSameEmailerror(true);
             }else if(response.data.error === '0' && response.data.msg === 'account created') {
                setAccountRegister(true)
                localStorage.setItem('userData', JSON.stringify(response.data.userData));
                dispatch({ type: 'LOGIN', payload: response.data.userData });
                setIsLoggedIn(true)
                setTimeout(() => {
                    navigate('/');
                  }, 3000);
             }  
              
          } catch (error) {
              console.error('API request error:', error);
          }
        }else{ 

            if (!Firstname.trim()) {
                setFirstnameError(true);
              }
            if (!Lastname.trim()){
                setLastnameError(true);
            }   
            if (!phoneNumber.trim()){
                setPhoneNumberError(true);
            }
            if(!email.trim()){
                setemailError(true);
            }
            if(!bodValue.trim()){
                setdobError(true);
            }
            if (!password.trim()) {
                setPasswordError(true);
            }
            if (!confirmPassword.trim()) {
                setConfirmPasswordError(true);
            }
            if (password !== confirmPassword) {
                setDiffrentPasswordError(true);
            }
            
        }
      };
  
    // View Password Code
    const [showPasswords, setShowPasswords] = useState([false, false, false]);

    const togglePasswordVisibility = (index) => {
        const updatedShowPasswords = [...showPasswords];
        updatedShowPasswords[index] = !updatedShowPasswords[index];
        setShowPasswords(updatedShowPasswords);
    };


    const handlePasswordChange = (e, index) => {
        setDiffrentPasswordError(false);
        const value = e.target.value;
        if (index === 0) {
            setPassword(value);
            setPasswordError(false);
            const isPasswordValid = value.length >= 8;
            setPasswordRequirementsMet(isPasswordValid);
        } else {
            setConfirmPassword(value);
            setConfirmPasswordError(false);
        }
    };
    

    return(
        <>
            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">
    
                <div className='signinup-screen mw768ss active' id="registration-screen">

                    <div className='signinup-header'>
                        <Link to="/signin" className="back-btn" title="Back to Login">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <img className='signinup-logo' src={Img.logo} alt=" " />
                    </div>

                    <div className="screen-hed">Finish your registration</div>

                    <form>

                        <div className="row">

                            {/* <div className="col-12">
                                <div className="signinup-group">
                                    <label className="group__label">Gender</label>
                                    <div className="signinup-group-radio">
                                        <input type="radio" id="male" name="gender" hidden/>
                                        <label htmlFor="male">Male</label>
                                        <input type="radio" id="female" name="gender" hidden/>
                                        <label htmlFor="female">Female</label>
                                    </div>
                                </div>
                            </div> */}
            
                            <div className="col-6 pe-2">
                                <div className={`signinup-group ${FirstnameError ? 'error-group' : ''}`}>
                                    <div className="group__label">First Name</div>
                                    <input type="text" value={Firstname} placeholder="Enter first name" onChange={(e) => handleFirstNameChange(e)}/>
                                    {FirstnameError && <span className="form-error-msg">You must provide a first name</span>}
                                    {invalidFirstnameError && <span className="form-error-msg">First name can not be numbers.</span>}
                                </div>
                            </div>

                            <div className="col-6 ps-2">
                                <div  className={`signinup-group ${LastnameError ? 'error-group' : ''}`}>
                                    <div className="group__label">Last Name</div>
                                    <input type="text" value={Lastname} placeholder="Enter last name" onChange={(e) => handleLastNameChange(e)}/>
                                    {LastnameError && <span className="form-error-msg">You must provide last name</span>}
                                    {invalidLastnameError && <span className="form-error-msg">Last name can not be numbers.</span>}
                                </div>
                            </div>

                            <div className="col-sm-6 pe-sm-2">
                                <div  className={`signinup-group sgli ${phoneNumberError ? 'error-group' : ''}`}>
                                    <div className="group__label">Phone Number</div>
                                    <input type="number" value={phoneNumber} placeholder="Enter phone number" onChange={(e) => handlePhoneNumberChange(e)}/>
                                    <span className="group_left_icon">+91</span>
                                    {phoneNumberError &&<span className="form-error-msg">{samePhoneNumbererror ? 'phone number already exist.' : 'You must provide phone number'}</span>}
                                    {invalidPhoneNumberError && <span className="form-error-msg">Phone number can not be grater the 10 digits</span>}
                                </div>
                            </div>

                            <div className="col-sm-6 ps-sm-2">
                                <div  className={`signinup-group ${emailError ? 'error-group' : ''}`}>
                                    <div className="group__label">Your email</div>
                                    <input value={email} type="email" placeholder="you@example.com" onChange={(e) => handleEmailChange(e)}/>
                                    {emailError && <span className="form-error-msg">{sameEmailerror ? 'email already exist.' : 'You must provide an email'}</span>}
                                    {invalidemailError && <span className="form-error-msg">Enter an valid email address</span>}
                                </div>
                            </div>
                            
                            <div className="col-6 pe-2">
                                <div className="signinup-group sgri">
                                    <div className="group__label">Gender</div>
                                    <select value={gender} onChange={(e) => {setGender(e.target.value);}}>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                    <span className="down-icon"></span>
                                </div>
                            </div>
                            
                            <div className="col-6 ps-2">
                                <div  className={`signinup-group sgri ${dobError ? 'error-group' : ''}`}>
                                    <div className="group__label">Date of birth</div>
                                    <input type="text" inputMode="numeric"  value={bodValue}  onChange={handlebodChange} placeholder="DD/MM/YYYY"/>
                                    <span className="clear-input-icon" hidden={isbodShow} onClick={handlebodClear}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                                    </span>
                                    {dobError && <span className="form-error-msg">You must provide date of birth</span>}
                                </div>
                            </div>

                            {[0, 1].map((index) => (
                            <div className={`col-sm-6 ${[index] ? 'pe-sm-2' : 'ps-sm-2'}`} key={index}>
                            <div className="signinup-group sgri">
                                <div className="group__label">{index === 0 ? "Password" : "Confirm password"} </div>
                                <input
                                    type={showPasswords[index] ? 'text' : 'password'}
                                    placeholder={`Enter ${index === 0 ? "" : "confirm"} password`}
                                    value={index === 0 ? password : confirmPassword}
                                    onChange={(e) => handlePasswordChange(e, index)}
                                />
                                <span className={`group_right_icon ${showPasswords[index] ? "active" : ""}`} onClick={() => togglePasswordVisibility(index)}>
                                    {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                                </span>
                            </div>
                            {index === 0 && passwordError && <div className="form-error-msg">You must provide Password</div>}
                            {index === 1 && confirmPasswordError && <div className="form-error-msg">You must provide Confirm password</div>}
                        </div>
                            ))}
                            {diffrentPasswordError && <div className="form-error-msg">password and confirm password does not match.</div>}


                            <div className="col-12 pe-2">
                                <div className="signinup-group">
                                    <div className="group__label">Refer Code</div>
                                    <input type="text" value={ReferCode} placeholder="Do You Have Refer Code?" onChange={(e) => handleReferCodeChange(e)}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="site-info text-center">
                            By continuing you agree to Crash <span className="site-link" data-bs-target="#termsmodal" data-bs-toggle="modal">Terms & Conditions</span>.
                        </div>

                        <button type="button"  onClick={handleContinueClick}  className="login-submit-btn" >Continue</button>

                        {accountRegister && <FlashMessage type="success" isvisible={true} message="Account register successfully" />}

                        {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="Please wait" />Please wait ....
                        </div> */}

                        <div className="signuptooglebtn text-center mt-2">Already have an account?
                        <Link to='/signin' className='login-this'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                            Login
                        </Link>
                        </div>

                    </form>

                </div>

            </div>
        </>
    );
};