
const getApiHost = () => {
    if (window.location.hostname === 'localhost' && window.location.port === '3000') {
      return 'http://127.0.0.1:8000/api';
    } else if (window.location.hostname === 'crashgamezone.com' || window.location.hostname === 'www.crashgamezone.com') {
      return 'https://backend.crashgamezone.com/api';
    }else{
      return 'https://gamebackend.pmcommu.in/api';
    }
  };
  
  const getLiveToken = () => {
    if (window.location.hostname === 'localhost' && window.location.port === '3000') {
      return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMzOTIxNTk1LCJpYXQiOjE3MDIzODU1OTUsImp0aSI6IjBlNWE2Njg0YmMxNTQyN2I4MDU3OGM1ZGE0OGNiZTAxIiwidXNlcl9pZCI6MX0.DdYhXqgNrI5NTmnI-wpEI-SK4KTHZXuwZyuT6ni3vJU';
    } else {
      return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMzOTIxNTk1LCJpYXQiOjE3MDIzODU1OTUsImp0aSI6IjBlNWE2Njg0YmMxNTQyN2I4MDU3OGM1ZGE0OGNiZTAxIiwidXNlcl9pZCI6MX0.DdYhXqgNrI5NTmnI-wpEI-SK4KTHZXuwZyuT6ni3vJU';
    }
  };



  const getsocket_url = () => {
    if (window.location.hostname === 'localhost' && window.location.port === '3000') {
      return 'ws://127.0.0.1:8000/ws';
    } else if (window.location.hostname === 'crashgamezone.com' || window.location.hostname === 'www.crashgamezone.com') {
      return 'wss://backend.crashgamezone.com/ws';
    } else {
      return 'wss://gamebackend.pmcommu.in/ws';
    }
  };
  
  export const API_HOST = getApiHost();
  export const live_token = getLiveToken();
  export const socket_url=getsocket_url();

  