// AuthContext.js

import { createContext, useContext, useReducer, useEffect } from 'react';

const AuthContext = createContext();


const getLoggedInUserData = () => {
  const userData = localStorage.getItem('userData');
  return userData ? JSON.parse(userData) : null;
};  


const initialUser = getLoggedInUserData();

const initialState = {
  isLoggedIn: !!initialUser,
  userData: initialUser,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('userData', JSON.stringify(action.payload));
      return { isLoggedIn: true, userData: action.payload };
    case 'LOGOUT':
      localStorage.removeItem('userData');
      return { isLoggedIn: false, userData: null };
    default:
      return state;
  }
};




const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);


  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    if (loggedInUser) {
      dispatch({ type: 'LOGIN', payload: loggedInUser });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth, getLoggedInUserData };
