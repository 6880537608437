import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth  } from './contexts/AuthContext';



const RequireAuth = ({ allowedRoles }) => {
    const { state } = useAuth();
   
    const location = useLocation();
    return (
        (
        	((state?.isLoggedIn==true) && state?.userData && (Object.keys(state?.userData).length > 0)) ? (((allowedRoles?.includes(state?.isLoggedIn) == true))
                                    ? <Outlet />
                                    : <Navigate to="/unauthorized" state={{ from: location }} replace />)
                        : <Navigate to="/signin" state={{ from: location }} replace />


            /*((user?.token && (allowedRoles?.includes(user?.token) == true))
                        ? <Outlet />
                        : <Navigate to="/login" state={{ from: location }} replace />)*/
        )
    );
}

export default RequireAuth;
