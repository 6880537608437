import React from 'react';
import ReactDOM from 'react-dom/client';
import {AuthProvider} from './contexts/AuthContext'
import { HeaderProvider } from './contexts/HeaderProvider';

// Css File
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/font-awesome.min.css"
import "./css/commonform.css"
import "./css/style.css"

// Scripts
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 

// Design File
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <HeaderProvider>
    <App />
    </HeaderProvider>
  </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
