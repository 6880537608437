// HeaderContext.js
import React, { createContext, useState, useContext } from 'react';

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [headerValue, setHeaderValue] = useState('');

  const updateHeaderValue = (value) => {
    setHeaderValue(value);
  };

  return (
    <HeaderContext.Provider value={{ headerValue, updateHeaderValue }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};
