import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';
import RequireAuth from '../RequireAuth';
import { MenuProvider } from '../components/Header/MenuContext';


//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../components/Header/Header";


// Pages
import PageHome from "../containers/PageHome/PageHome";

// Signinup Pages
import PageSignIn from "../containers/PageSignInUp/PageSignIn";
import PageSignUp from "../containers/PageSignInUp/PageSignUp";
import PageSignOtp from "../containers/PageSignInUp/PageSignOtp";
import PageForgotPassword from "../containers/PageSignInUp/PageForgotPassword";
import PageResetPassword from "../containers/PageSignInUp/PageResetPassword";

//Accout Pages
import PageAccount from "../containers/PageAccount/PageAccount";
import PageWallet from "../containers/PageAccount/PageWallet";
import PageBankaccount from "../containers/PageAccount/PageBankDetail";
// import PageNotifications from "../containers/PageAccount/PageNotifications";

// Lagle Pages
// import PageAbout from "../containers/PageLagle/PageAbout";
import PageFaqs from "../containers/PageLagle/PageFaqs";
// import PagePrivacy from "../containers/PageLagle/PagePrivacy";
import PageTerms from "../containers/PageLagle/PageTerms";
import PageDeposit from "../containers/PageLagle/PageDeposit";

// Contact Page
// import PageContact from "../containers/PageContact/PageContact";

// Sitemap page
import PageSitemap from "../containers/PageLagle/PageSitemap";

// Footer section
import FlashMessage from "../components/FlashMessage";
import FlashMessagetwo from "../components/FlashMessagetwo";
import CommonModal from "../components/CommonModal";
// import Footer from "../components/Footer/Footer";



export const pages = [
  { path: "/", component: PageHome, allowedRoles : true },
  // { path: "/home", component: PageHome },
  
  { path: "/signin", component: PageSignIn, allowedRoles : false },
  { path: "/signup", component: PageSignUp, allowedRoles : false },
  { path: "/signotp", component: PageSignOtp, allowedRoles : false },
  { path: "/forgotpassword", component: PageForgotPassword, allowedRoles : false },
  { path: "/resetpassword/:forgetPasswordToken/", component: PageResetPassword, allowedRoles: false },

   
  { path: "/account", component: PageAccount, allowedRoles : true },
  { path: "/wallet", component: PageWallet, allowedRoles : true },
  { path: "/bankaccount", component: PageBankaccount, allowedRoles : true },
  // { path: "/notifications", component: PageNotifications, allowedRoles : true },

  // { path: "/about", component: PageAbout, allowedRoles : true },
  { path: "/faqs", component: PageFaqs, allowedRoles : true },
  // { path: "/privacy", component: PagePrivacy, allowedRoles : true },
  { path: "/terms", component: PageTerms, allowedRoles : true },
  { path: "/how-to-make-deposit", component: PageDeposit, allowedRoles : true },

  // { path: "/contact", component: PageContact, allowedRoles : true },

  { path: "/sitemap", component: PageSitemap, allowedRoles : true },
];

const MyRoutes = () => {

  const location = useLocation();

  const pathsToHideheader = ['/signin', '/signup', '/signotp', '/forgotpassword', '/resetpassword'];
  // const pathsToHidefooter = ['/signin', '/signup', '/signotp', '/account', '/wallet', '/bankaccount', '/notifications', '/forgotpassword', '/resetpassword' ];
  const hideheader = pathsToHideheader.includes(location.pathname);
  // const hidefooter = pathsToHidefooter.includes(location.pathname);

  return (
    <>
      <ScrollToTop />
      <Loader />
      {!hideheader && <Header />}
      <main className="main-wrapper">
        <Routes>
          {pages.map(({ component: Component, path,allowedRoles}, index) => {

            if(allowedRoles && allowedRoles === true)
            {
              return (<Route key={index} element={<RequireAuth allowedRoles={[true]} />} >
                        <Route element={<Component />} path={path} />
              </Route>);
            }
            else
            {
              return <Route key={index} element={<Component />} path={path} />;
            }
    
          })}
        </Routes>
      </main>

      <FlashMessage />
      <FlashMessagetwo />
      <CommonModal />
      {/* {!hidefooter && <Footer />} */}
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <MenuProvider>
        <MyRoutes />
      </MenuProvider>
    </Router>
  );
}

export default MainRoutes;