import React, { useEffect, useState, useCallback, useRef } from 'react';

import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import axios from 'axios';
import { API_HOST, live_token, socket_url } from '../../configue/confifigue'
import Subloader from "../../components/Subloader"
import FlashMessage from '../../components/FlashMessage'
import FlashMessagetwo from '../../components/FlashMessagetwo'
import { useHeader } from '../../contexts/HeaderProvider';


//  Images Common File
import * as Img from '../../components/Img';
import giplanelg from "../../img/aitplane/giplanelg.png";

export default function PageGamesreen() {
  const [isLoading, setIsLoading] = useState(true);

  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isGameEnd, setIsGameEnd] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(-30);
  const [mountains, setMountains] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [winIndicatorShow, setWinIndicatorShow] = useState(false);
  const [winCounter, setWinCounter] = useState(1);
  const GSBX = document.querySelector('.gi-step-bx');
  const [RandomTime, SetRandomTime] = useState('');
  const [isbetShow, setisbetShow] = useState(true);
  const [betValue, setbetValue] = useState('');
  const BeatValueRef = useRef();
  let abc

  const WinCounterIntervalRef = useRef(null);
  const [load, setLoad] = useState(true);
  const [gameData, setGameData] = useState([]);




  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [userEmail, setUserEmail] = useState('');
  const userDataRef = useRef();
  const { state } = useAuth();
  const { updateHeaderValue, headerValue } = useHeader();
  const [enablePlaceBeat, setEnablePlaceBeat] = useState(true);
  const [hasPlacedBet, setHasPlacedBet] = useState(false);
  const [enableTakeAWin, setenableTakeAWin] = useState(false);

  const [isEnoughBalance, setIsEnoughBalance] = useState(false);
  const enoughbalanceRef = useRef();
  const [isEnoughBalanceTwo, setIsEnoughBalanceTwo] = useState(false);
  const enoughbalanceTwoRef = useRef();

  const [userIsInGame, setUserIsInGame] = useState(false);
  const userIsInGameRef = useRef();

  // my varaibles 
  const [changePlaceBeatText, setChangePlaceBeatText] = useState(false);
  const [changeTakeAWinText, setchangeTakeAWinText] = useState(false);
  const [DisableInputsBoxes, setDisableInputsBoxes] = useState(false);

  const [winningAMount, setwinningAMount] = useState('');

  const [win, setWin] = useState(false);
  const userWinRef = useRef();
  const [lose, setLose] = useState(false);
  const userloseRef = useRef();

  const [currentGameId, setCurrentGameId] = useState('');
  const userCurrentGameIdRef = useRef();


  const [TotalBetsInGame, setTotalBetsInGame] = useState(0);
  const [TotalUsersInGame, setTotalUsersInGame] = useState(0);
  const [TotalWInningsInGame, setTotalWInningsInGame] = useState(0);

  // my varaibles end
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorMessageTwo, setShowErrorMessageTwo] = useState(false);
  const [bbc, setbbc] = useState(1);
  const [noSecondGame, setNoSecondGame] = useState(false);
  const usenoSecondGameRef = useRef();



  useEffect(() => {
    if (enoughbalanceRef.current) {
      setShowErrorMessage(true);
      const timeoutId = setTimeout(() => {
        setShowErrorMessage(false);
        setIsEnoughBalance(false)
        enoughbalanceRef.current = false
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [enoughbalanceRef.current]);

  useEffect(() => {
    if (enoughbalanceTwoRef.current) {
      setShowErrorMessageTwo(true);
      const timeoutId = setTimeout(() => {
        setShowErrorMessageTwo(false);
        setIsEnoughBalanceTwo(false)
        enoughbalanceTwoRef.current = false
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [enoughbalanceTwoRef.current]);



  useEffect(() => {
    if (countdown == 0) {
      setEnablePlaceBeat(false);
      setHasPlacedBet(true);
      setDisableInputsBoxes(true);
      // if (userIsInGameRef.current) {
      //   setenableTakeAWin(true);
      // }
    } else if (countdown == 10) {
      setLose(false);
      userloseRef.current = false;
      setEnablePlaceBeat(true);
      setHasPlacedBet(false);
    }
  }, [countdown]);

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      setUserLoggedIn(true);
      setUserEmail(loggedInUser);
      userDataRef.current = loggedInUser;
    }
  }, [state.userData]);





  const checkWalletBalance = (value) => {
    if (headerValue >= value) {
      setbetValue(prevBetValue => String(Number(prevBetValue) + value));
      BeatValueRef.current = value;
    } else {
      setIsEnoughBalanceTwo(true)
      enoughbalanceTwoRef.current = true
    }
  };





  const minusBalance = async (user_id, minusValue) => {
    try {
      const token = live_token
      const response = await axios.post(`${API_HOST}/minus-wallet-blance/`, {
        user_id: user_id,
        minus_amount: minusValue,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      updateHeaderValue(response.data.wallet.balance);


    } catch (error) {
      console.error('API request error:', error);
    }
  };






  const socketRef = useRef(null);

  const openSocketConnection = (user_id, user_email, beat_amount, currentGameId, winvalue, won_amount, game_status) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.close();
    }

    const url = `${socket_url}/play-game/`;
    socketRef.current = new WebSocket(url);

    socketRef.current.onopen = function (event) {


      const initialData = {
        user_id,
        user_email,
        beat_amount,
        currentGameId,
        winvalue,
        won_amount,
        game_status,
      };

      socketRef.current.send(JSON.stringify(initialData));
    };

    socketRef.current.onmessage = function (event) {
      const data = JSON.parse(event.data);
      if ('message' in data) {
        const { message } = data;
        if (message === 'You are already in the game.') {
          setUserIsInGame(false);
          userIsInGameRef.current = false;
          setNoSecondGame(true);
          usenoSecondGameRef.current = true;
          setDisableInputsBoxes(true);
          setHasPlacedBet(true);
        }
        else if (message === 'bet accepted,go ahead.') {
          // setUserIsInGame(true);
          // userIsInGameRef.current = true;
          setHasPlacedBet(true);
          setChangePlaceBeatText(true);
          minusBalance(userEmail.id, betValue)
          setDisableInputsBoxes(true);
        }
      }
      setTotalBetsInGame(0)
      setTotalBetsInGame(data.total_beat_amount)
      setTotalUsersInGame(0)
      setTotalUsersInGame(data.total_users)
      setTotalWInningsInGame(0)
      setTotalWInningsInGame(data.toatl_winnings)
      const { all_users } = data;


      if (Array.isArray(all_users)) {
        setGameData([]);
        setGameData(all_users);
      }
    };

    socketRef.current.onclose = function (event) {
    };
  };

  useEffect(() => {
    if (countdown === 10 && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.close();
    }
  }, [countdown]);





  const PlusBalance = async (user_id, added_value, winvalue) => {

    try {
      const token = live_token
      const response = await axios.post(`${API_HOST}/plus-wallet-blance/`, {
        user_id: user_id,
        added_value: added_value,
        winvalue, winvalue,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      updateHeaderValue(response.data.wallet.balance);
      setwinningAMount(response.data.add_amount);
      setWin(true);
      userWinRef.current = true
      let empty_beat_amount = ''
      let game_status = 'WON'
      openSocketConnection(userEmail.id, userEmail.email, empty_beat_amount, userCurrentGameIdRef.current, response.data.winvalue, response.data.add_amount, game_status);


    } catch (error) {
      console.error('API request error:', error);
    }
  };


  useEffect(() => {
    if (userCurrentGameIdRef.current) {
      const user_id = userEmail.id;
      const user_email = userEmail.email;
      const beat_amount = '';

      openSocketConnection(user_id, user_email, beat_amount, userCurrentGameIdRef.current);
    }

  }, [userCurrentGameIdRef.current]);



  const placeaBeat = () => {
    const balanceAsInteger = parseInt(headerValue, 10);
    const betValueAsInteger = parseInt(betValue, 10);

    if (!betValueAsInteger || betValueAsInteger < 20) {
      setIsEnoughBalance(true)
      enoughbalanceRef.current = true
    } else if (balanceAsInteger < betValueAsInteger) {
      setIsEnoughBalanceTwo(true)
      enoughbalanceTwoRef.current = true
    }
    else {
      openSocketConnection(userEmail.id, userEmail.email, betValueAsInteger, currentGameId);
      setUserIsInGame(true);
      userIsInGameRef.current = true;
    }
  };




  const startGame = useCallback((datas) => {
    setIsGameStarted(true);
    setWinIndicatorShow(true);
    if (userIsInGameRef.current) {
      setenableTakeAWin(true);
    }



    const timeoutId = setTimeout(() => {

      setIsGameEnd(true);

      setTimeout(() => {
        setMountains(false);
      }, 5100);

      setTimeout(() => {
        setIsGameEnd(false);
        setIsGameStarted(false);
      }, 5000);

      setTimeout(() => {
        setMountains(true);
      }, 4800);

      setTimeout(() => {
        setWinCounter(1);
      }, 4000);


      setenableTakeAWin(false);
      if (userIsInGameRef.current) {
        setLose(true);
        userloseRef.current = true;
        let empty_beat_amount = ''
        let win_value = ''
        let add_amount = ''
        let game_status = 'LOSE'
        openSocketConnection(userDataRef.current.id, userDataRef.current.email, empty_beat_amount, userCurrentGameIdRef.current, win_value, add_amount, game_status);
      }

      setTimeout(() => {
        setNoSecondGame(false);
        usenoSecondGameRef.current = false;
        setWinIndicatorShow(false);
        setEnablePlaceBeat(false);

        setWaiting(true);
        setUserIsInGame(false);
        userIsInGameRef.current = false;
        setChangePlaceBeatText(false);
        setwinningAMount('');
        setWin(false);
        userWinRef.current = false;
        setchangeTakeAWinText(false);
        setDisableInputsBoxes(false);
        setGameData([]);
        setbetValue('');
      }, 2000);


      setTimeout(() => {
        clearInterval(WinCounterIntervalRef.current);
      }, 0);


    }, datas);


    return () => clearTimeout(timeoutId);
  }, [GSBX]);





  useEffect(() => {

    WinCounterIntervalRef.current = setInterval(() => {

      if (isGameStarted === true) {
        setWinCounter((prevWinCounter) => prevWinCounter + 0.01);

      } else {
        clearInterval(WinCounterIntervalRef.current);
      }
    }, 100);

    return () => clearInterval(WinCounterIntervalRef.current);
  }, [isGameStarted, isGameEnd]);






  useEffect(() => {
    const socket = new WebSocket(`${socket_url}/start-game/`);
    socket.onopen = () => {
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if ('count' in data && data.count !== undefined) {

        setCurrentGameId('')
        userCurrentGameIdRef.current = ''
        setCurrentGameId(data.game_id)
        userCurrentGameIdRef.current = data.game_id
        setLoad(false);
        setCountdown(data.count)
        setWaiting(false)


      }

      if ('plane_crash_time' in data && data.plane_crash_time !== undefined) {
        let abc = data.plane_crash_time
        startGame(abc);
      }
    };

    socket.onclose = () => {
    };

    return () => {
      socket.close();
    };
  }, []);







  const handlebetChange = (e) => {
    setIsEnoughBalance(false)
    enoughbalanceRef.current = false
    setIsEnoughBalanceTwo(false)
    enoughbalanceTwoRef.current = false
    const value = e.target.value.replace(/\D/g, '');
    setisbetShow(value === '');
    setbetValue(value);
    BeatValueRef.current = value;
  };


  const handlebetClear = () => {
    setbetValue("");
    setisbetShow(true)
  };



  const getCurrentBeatValues = () => {
    if (userIsInGame == true) {
      setenableTakeAWin(false);
      setUserIsInGame(false);
      userIsInGameRef.current = false;
      setchangeTakeAWinText(true);
      socketRef.current.close();
      PlusBalance(userEmail.id, betValue, winCounter)
    }

  };


  const getClassByGameStatus = (gameStatus) => {
    switch (gameStatus) {
      case 'IN-GAME':
        return '';
      case 'WON':
        return 'win-game';
      case 'LOSE':
        return 'lost-game';
      default:
        return '';
    }
  };



  return (
    <>
      {load && <Subloader />}

      <section className="pt-lg-4 pt-0 pb-4 pb-lg-4">
        <div className="gamesreen-container">
          <div className="row">

            {/* Left Section start */} {/* Players List table */}
            <div className="col-xl-3 col-lg-4 order-2 order-lg-1 mt-4 mt-lg-0">
              <div className="gamesreen-common-bx gs-table-radius p-0">
                <div className="gs-table-heading-bx gs-player-table-heading-bx">

                  <div className="gs-table-heading-item">
                    Number of players<br />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z" clipRule="evenodd" />
                      <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
                    </svg>
                    {TotalUsersInGame}
                  </div>

                  <div className="gs-table-heading-item">
                    Total bets <br />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                      <path fillRule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clipRule="evenodd" />
                      <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
                    </svg>
                    {TotalBetsInGame}
                  </div>

                  <div className="gs-table-heading-item">
                    Total winnings <br />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                      <path fillRule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clipRule="evenodd" />
                      <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
                    </svg>
                    {TotalWInningsInGame}
                  </div>

                </div>

                <div className="custom-table-responsive gs-table-responsive gs-players-table-height ctr-scrollbar">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Odds</th>
                        <th>Bet</th>
                        <th>Win</th>
                      </tr>
                    </thead>

                    {/* <tbody>
                      {gameData.map((userData, index) => (
                        <tr key={index} className={getClassByGameStatus(userData.game_status)}>
                          <td>{`${userData.user.first_name} ${userData.user.last_name}`}</td>
                          <td>{userData.odds || 'x0'}</td>
                          <td>{`${userData.beat_amount} INR`}</td>
                          <td>{userData.winning_amount || '0 INR'}</td>
                        </tr>
                      ))}

                    </tbody> */}
                    <tbody>
                      {gameData.map((userData, index) => {
                        const firstName = userData.user.first_name.slice(0, 2);
                        const lastName = userData.user.last_name.slice(0, 2);

                        return (
                          <tr key={index} className={getClassByGameStatus(userData.game_status)}>
                            <td>{`${firstName}** ${lastName}**`}</td>
                            <td>{userData.odds || 'x0'}</td>
                            <td>{`${userData.beat_amount} INR`}</td>
                            <td>{userData.winning_amount || '0 INR'}</td>
                          </tr>
                        );
                      })}
                    </tbody>


                  </table>
                </div>
              </div>
            </div>
            {/* Left Section End */}

            {/* Right Section */} {/* Game Screen, Game played history table, Game beting buttons */}
            <div className="col-xl-9 col-lg-8 order-1 order-lg-2">

              <div className="row justify-content-center">

                {/* Game Screen */}
                <div className="col-12 px-0">

                  <div className="gamesreen-top-buttons">
                    <div className="gs-tb-item" data-bs-target="#Sendvideourl" data-bs-toggle="modal">T & C</div>
                  </div>

                  <div className={`gamesreen-main-bx ${isGameStarted ? 'game-start' : ''} ${isGameEnd ? 'game-end' : ''}`}>
                    <div className="container-lg px-0">
                      <div className="gamesreen-bx">
                        <div className="gamesreen-inner">

                          <div className="gi-airplane-bx">
                            <div className="gi-img-item gi-airplane"></div>
                            <div className="gi-img-item crash-img"></div>
                            <img src={giplanelg} alt="" />
                          </div>

                          <div className={`gi-mountains-bx ${mountains ? "active" : ""}`}>
                            <div className="gi-mountain gim-5"></div>
                            <div className="gi-mountain gim-4"></div>
                            <div className="gi-mountain gim-3"></div>
                            <div className="gi-mountain gim-2"></div>
                            <div className="gi-mountain gim-1"></div>
                          </div>

                          <div className="dots dots-x"></div>
                          <div className="dots dots-y"></div>
                          <div className='gi-step-indicator'></div>


                          <div className="gi-countdown-bx">
                            <div className="gi-countdown">{countdown}</div>
                          </div>


                          <div className={`gi-waiting ${waiting ? "active" : " "}`}>
                            <div className="gi-waiting-img">
                              <div className="gi-wi-airplane"></div>
                              <div className="gi-wi-after"></div>
                            </div>
                            <div className="gi-wi-text">waiting for you to place your first bet...</div>
                          </div>

                          <div className={`gi-win-indicator ${winIndicatorShow ? "active" : ""} `}>{winCounter.toFixed(2)}X</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Game played history table */}
                {/* <div className="col-xl-7 col-md-6 order-2 order-md-1 mt-4">
                  <div className="gamesreen-common-bx gs-table-radius p-0">

                    <div className="gs-table-heading-bx">
                      <div className="gs-table-heading-item">
                        <svg xmlns="http:www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg> History
                      </div>
                    </div>

                    <div className="custom-table-responsive gs-table-responsive ctr-scrollbar">
                      <table className="custom-table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Bet</th>
                            <th>Odds</th>
                            <th>Win</th>
                            <th>Crash</th>
                          </tr>
                        </thead>

                        <tbody>

                          <tr>
                            <td>13/12/2023</td>
                            <td>11:03 AM</td>
                            <td>{'\u20B9'}5000</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>

                          <tr className="win-game">
                            <td>13/12/2023</td>
                            <td>11:03 AM</td>
                            <td>{'\u20B9'}5000</td>
                            <td>2X</td>
                            <td>{'\u20B9'}10000</td>
                            <td>3.00X</td>
                          </tr>

                          <tr className="lost-game">
                            <td>13/12/2023</td>
                            <td>11:03 AM</td>
                            <td>{'\u20B9'}5000</td>
                            <td>-</td>
                            <td>-</td>
                            <td>3.00X</td>
                          </tr>

                          <tr className="win-game">
                            <td colSpan="6">
                              <div className="empty-div-bx p-0" style={{ minHeight: "100%" }}>
                                <img className="empty-img" src={Img.empty} alt="Data is not available" />
                                <div className="empty-sub-heading mt-2">You haven't placed any bets yet</div>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                  </div>
                </div> */}


                {/* Game beting buttons */}
                <div className="col-xl-7 col-md-6 order-1 order-md-2 mt-4">
                  <div className="gamesreen-common-bx">
                    <div className="gcb-heading">Stake Selector</div>

                    <form className="form-white">
                      <div className="row">
                        <div className="col-12">

                          <div className="signinup-group sgri">
                            <input type="number" value={betValue} onChange={handlebetChange} disabled={DisableInputsBoxes} placeholder="Enter bet amount" />
                            {enoughbalanceRef.current && showErrorMessage && <span className="form-error-msg">munimum {'\u20B9'}20 required for place a beat</span>}
                            {enoughbalanceTwoRef.current && showErrorMessageTwo && <span className="form-error-msg">You don't have enough wallet balance to place bet</span>}

                            <span className="clear-input-icon" hidden={isbetShow} onClick={handlebetClear}>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368" /></svg>
                            </span>
                          </div>

                          <div className="beting-btns-group">
                            <div onClick={() => checkWalletBalance(20)} style={{ pointerEvents: DisableInputsBoxes ? 'none' : 'auto' }} className={`beting-btn ${DisableInputsBoxes ? 'disabled' : ''}`}>20</div>
                            <div onClick={() => checkWalletBalance(90)} style={{ pointerEvents: DisableInputsBoxes ? 'none' : 'auto' }} className={`beting-btn ${DisableInputsBoxes ? 'disabled' : ''}`}>90</div>
                            <div onClick={() => checkWalletBalance(300)} style={{ pointerEvents: DisableInputsBoxes ? 'none' : 'auto' }} className={`beting-btn ${DisableInputsBoxes ? 'disabled' : ''}`}>300</div>
                            <div onClick={() => checkWalletBalance(500)} style={{ pointerEvents: DisableInputsBoxes ? 'none' : 'auto' }} className={`beting-btn ${DisableInputsBoxes ? 'disabled' : ''}`}>500</div>
                            <div onClick={() => checkWalletBalance(1000)} style={{ pointerEvents: DisableInputsBoxes ? 'none' : 'auto' }} className={`beting-btn ${DisableInputsBoxes ? 'disabled' : ''}`}>1000</div>
                            <div onClick={() => checkWalletBalance(2000)} style={{ pointerEvents: DisableInputsBoxes ? 'none' : 'auto' }} className={`beting-btn ${DisableInputsBoxes ? 'disabled' : ''}`}>2000</div>
                          </div>

                          <div className="beting-place_take-btns">
                            <button onClick={placeaBeat} type="button" disabled={!enablePlaceBeat || hasPlacedBet}> {changePlaceBeatText ? "Bet Accepted" : "Place a bet"}</button>
                            <button onClick={getCurrentBeatValues}
                              type="button" disabled={!enableTakeAWin}>{changeTakeAWinText ? "Winnings Accepted" : "Take Winnings"}</button>
                          </div>

                          {/* {enoughbalanceRef.current && <FlashMessage type="error" isvisible={true} message="Not Enough Amount In Wallet. " />} */}
                          {lose && <FlashMessagetwo color="red" amount={betValue} isvisible={true} />}
                          {win && <FlashMessagetwo color="green" amount={winningAMount} isvisible={true} />}
                          {noSecondGame && <FlashMessage type="error" isvisible={true} message="You are already in game." />}
                        </div>
                      </div>
                    </form>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </section>

      {/* Send video URL Popup */}
      <div className="modal fade zoom" id="Sendvideourl" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-content">
            <div className="custom-modal-header">
              <div className="cmh-lable">How to Play</div>

              <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                </svg>
              </span>
            </div>
            <div className="custom-modal-data">
              <div className="legal-content px-3 pb-3">
                <p>1. Place a bet. The initial stake is determined before the start of a game.</p>
                <p>2. The maximum stake is 46637.8 INR and the minimum stake is 20 INR.</p>
                <p>3. As soon as each round begins, the odds start to increase until a crash occurs.</p>
                <p>4. If you manage to press "Take winnings" before the crash, then your stake will be multiplied by the stated odds.</p>
                <p>5. The crash occurs at random, but the longer you stay in the game, the higher the odds and the more you win.</p>
                <p>6. If you have not taken your winnings by the time the crash happens, the game will end and your stake will be lost.</p>
                <p>7. All you need to succeed is a stable internet connection (wired, Wi-Fi or LTE).</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

