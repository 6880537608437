import React, { useState, useEffect } from 'react';
import {Link,useParams,useNavigate} from "react-router-dom";
import { API_HOST, live_token } from '../../configue/confifigue';
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import FlashMessage from '../../components/FlashMessage'
import axios from 'axios';


// Images Common File
import * as Img from '../../components/Img';

// Css File
import '../../css/signinup.css';

const hidepassSvg = () => {
    return(
    <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>
    )
}

const viewpassSvg = () => {
    return(
    <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
    )
}

export default function PageResetPassword() {

    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const { dispatch, state } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();


    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [diffrentPasswordError,setDiffrentPasswordError] = useState(false);
    const [passwordRequirementsMet, setPasswordRequirementsMet] = useState(false);

    const[passwordReset,setpasswordReset]=useState(false);
    const[passwordResetError,setPasswordResetError]=useState(false);

    const { forgetPasswordToken } = useParams();

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
      
        if (isUserLoggedIn) {
          setUserLoggedIn(true);
          navigate('/')
        }else{
            if(!forgetPasswordToken.trim()){
                navigate('/')
            }
        }
    }, [state.userData]);

    const [showPasswords, setShowPasswords] = useState([false, false, false]);

    const togglePasswordVisibility = (index) => {
        const updatedShowPasswords = [...showPasswords];
        updatedShowPasswords[index] = !updatedShowPasswords[index];
        setShowPasswords(updatedShowPasswords);
    };


    const handlePasswordChange = (e, index) => {
        setDiffrentPasswordError(false);
        const value = e.target.value;
        if (index === 0) {
            setPassword(value);
            setPasswordError(false);
            const isPasswordValid = value.length >= 8;
            setPasswordRequirementsMet(isPasswordValid);
        } else {
            setConfirmPassword(value);
            setConfirmPasswordError(false);
        }
    };


    
    const handleContinueClick = async () => {

        if (forgetPasswordToken.trim() && password.trim() && confirmPassword.trim() && password === confirmPassword){

          try {
              const token = live_token
  
              const response = await axios.post(`${API_HOST}/change-password/`, {
                forgetPasswordToken:forgetPasswordToken,
                password : password,
                confirm_password : confirmPassword,
              }, {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  },
              });
                
            if(response.data.error === '0' && response.data.msg === 'Password updated successfully') {
                setpasswordReset(true)
                localStorage.setItem('userData', JSON.stringify(response.data.userData));
                dispatch({ type: 'LOGIN', payload: response.data.userData });
                setIsLoggedIn(true)
                setTimeout(() => {
                    navigate('/');
                  }, 5000);
             }else{
                setPasswordResetError(true)
             }  
              
          } catch (error) {
              console.error('API request error:', error);
          }
        }else{ 

            if (!password.trim()) {
                setPasswordError(true);
            }
            if (!confirmPassword.trim()) {
                setConfirmPasswordError(true);
            }
            if (password !== confirmPassword) {
                setDiffrentPasswordError(true);
            }
            
        }
      };



    return(
        <>
            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">
    
                <div className='signinup-screen active' id="resetpassword-screen">

                    <div className='signinup-header'>
                        <Link to="/signin" className="back-btn" title="Back to Login">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <img className='signinup-logo' src={Img.logo} alt='' />
                    </div>

                    <div className="screen-hed text-center">Reset password?</div>

                    <form>

                        <div className="row">
    
                        {[0, 1].map((index) => (
                            <div className={`col-sm-12 ${[index] ? 'pe-sm-2' : 'ps-sm-2'}`} key={index}>
                            <div className="signinup-group sgri">
                                <div className="group__label">{index === 0 ? "Password" : "Confirm password"} </div>
                                <input
                                    type={showPasswords[index] ? 'text' : 'password'}
                                    placeholder={`Enter ${index === 0 ? "" : "confirm"} password`}
                                    value={index === 0 ? password : confirmPassword}
                                    onChange={(e) => handlePasswordChange(e, index)}
                                />
                                <span className={`group_right_icon ${showPasswords[index] ? "active" : ""}`} onClick={() => togglePasswordVisibility(index)}>
                                    {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                                </span>
                            </div>
                            {index === 0 && passwordError && <div className="form-error-msg">You must provide Password</div>}
                            {index === 1 && confirmPasswordError && <div className="form-error-msg">You must provide Confirm password</div>}
                        </div>
                            ))}
                            {diffrentPasswordError && <div className="form-error-msg">password and confirm password does not match.</div>}
                        
                        </div>

                        <div className="site-info text-center">
                            By continuing you agree to Crash <span className="site-link" data-bs-target="#termsmodal" data-bs-toggle="modal">Terms & Conditions</span>.
                        </div>

                        <button type="button" onClick={handleContinueClick}  className="login-submit-btn">Reset Account</button>

                        {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="Please wait" />Please wait ....
                        </div> */}

                        <div className="signuptooglebtn text-center mt-2">
                        <Link to='/signin' className='login-this'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                            Back to Login
                        </Link>
                        </div>
                    </form>
                    {passwordReset && <FlashMessage type="success" isvisible={true} message="your password has been changed" />}
                    {passwordResetError && <FlashMessage type="error" isvisible={true} message="Your change password link has been expired" />}

                </div>

            </div>
        </>
    );
};